<template>
  <div class="printing-count">
    <div class="section">
      <condition :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :conditionFrom="conditionFrom" :isMultiple="true" @search="search"></condition>

<!--      <div class="table-box" v-if="listState.length">-->
<!--        <el-table :data="listState" border>-->
<!--          <el-table-column prop="count" label="统计全部" align="left"/>-->
<!--          <el-table-column prop="wtjcount" label="未提交" align="left"/>-->
<!--          <el-table-column prop="wshcount" label="未审核" align="left"/>-->
<!--          <el-table-column prop="yshcount" label="已审核" align="left"/>-->
<!--        </el-table>-->
<!--      </div>-->
      <div class="content" v-if="data.length">
        <div v-for="(item,index) in data" :key="index">
          <!-- <div style="padding: 20px 0;">{{item.name}}</div> -->
          <div style="display: flex;justify-content: space-between;align-items: baseline;">
            <h2>{{ item.name }}</h2>
            <el-button
                style="margin-right: 20px"
                type="primary"
                @click="handleDetails(item)"
            >
              {{ $t('checkDetail') }}
            </el-button>
          </div>
          <div class="table-box" >
<!--            <div v-for="(list,listIndex) in item.table2" :key="listIndex">-->
              <el-table :data="item.table2" border  height="300">
                <el-table-column prop="name" :label="$t('device')" align="left"/>
                <el-table-column prop="countTime" :label="$t('total_statistics')" align="left">
                  <template slot-scope="scope">
                    <span>{{scope.row.count ? scope.row.count + $t('ci') : '0' + $t('ci')}}/{{scope.row.countTime ? (scope.row.countTime/60).toFixed(1) : '0' }}{{ $t('hours') }}/{{scope.row.countTime ? scope.row.countTime + $t('min') : '0' + $t('min')}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="wtjcount" :label="$t('notSubmit')" align="left">
                  <template slot-scope="scope">
                    <span>{{!scope.row.wtjcount ? '0' + $t('ci') : scope.row.wtjcount + $t('ci')}}/{{!scope.row.wtjcountTime ? '0' : (scope.row.wtjcountTime/60).toFixed(1)}}{{ $t('hours') }}/{{!scope.row.wtjcountTime ? '0' + $t('min') : scope.row.wtjcountTime + $t('min')}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="wshcount" :label="$t('unreviewed')" align="left">
                  <template slot-scope="scope">
                    <span>{{!scope.row.wshcount ? '0' + $t('ci') : scope.row.wshcount + $t('ci')}}/{{!scope.row.wshcountTime ? '0' : (scope.row.wshcountTime/60).toFixed(1)}}{{ $t('hours') }}/{{!scope.row.wshcountTime ? '0' + $t('min') : scope.row.wshcountTime + $t('min')}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="yshcount" :label="$t('reviewed')" align="left">
                  <template slot-scope="scope">
                    <span>{{!scope.row.yshcount ? '0' + $t('ci') : scope.row.yshcount + $t('ci')}}/{{!scope.row.yshcountTime ? '0' : (scope.row.yshcountTime/60).toFixed(1)}}{{ $t('hours') }}/{{!scope.row.yshcountTime ? '0' + $t('min') : scope.row.yshcountTime + $t('min')}}</span>
                  </template>
                </el-table-column>
              </el-table>
<!--            </div>-->
          </div>
          <div v-if="item.deviceList.length && item.deviceList[0].abnormalList.length" :id="`selected${index}enterprise${index}`" class="echatsImg" style="margin-bottom: 20px"></div>
          <div v-else>{{$t('noData')}}</div>
          <div v-if="item.deviceList.length && item.deviceList[0].abnormalList.length" :id="`selected${index}time${index}`" class="echatsImg"></div>
          <div v-else>{{$t('noData')}}</div>
          <div v-if="item.deviceList.length">
            <div class="chartLayout" style="display: flex">
              <div class="chart" v-for="(item1,line) in item.deviceList" :key="`${index}${line}`" v-if="item1.valueList">
                <div v-if="item1.valueList">
                  <div class="title">
                    <div class="deviceName">{{ item1.deviceName }}</div>
                    <div style="width: 305px" :style="isShowDetail ? '' : {'text-align': 'right'}">
                      <el-button v-if="item1.valueList.length" type="primary" @click="handleCheckBig(item1)">{{ $t('view_large_image') }}</el-button>
                      <el-button class="right-center" type="primary" @click="handleDetail(item1)"
                                 v-if="isShowDetail && item1.valueList.length">
                        {{ $t('view_details') }}
                      </el-button>
                    </div>
                  </div>
                  <div v-if="item1.valueList.length" :id="item1.deviceId ? item1.deviceId : `device${index}${line}`"
                       class="echatsImg"></div>
                  <div v-else style="display: flex;margin-top: 135px; justify-content: center;">{{ $t('noData') }}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="chart">
            <div style="display: flex;margin-top: 135px; justify-content: center;">{{ $t('noData') }}</div>
          </div>
        </div>
      </div>

    </div>
    <abnormal-shutdown-dialog
      :dialogVisible.sync="dialogVisible"
      :detail="detail"
      :query="param"
      @close="dialogClose"
    ></abnormal-shutdown-dialog>
    <el-dialog :title="titleBig" width="50%" height="50%" @close="handleClose" :visible.sync="dialogTableVisibleBig"
               :modal-append-to-body="false" :append-to-body="false" :close-on-click-modal="false">
      <div>
        <!--          v-if="line.deviceList.length > 0"-->
        <dialogDig :formListBig="formListBig"></dialogDig>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import condition from './condition'
import abnormalShutdownDialog from './abnormalShutdownDialog'
import API from '@/api';
import dialogDig from './dialogDig'
import LineChart3 from "@/components/ChartsTime/LineChart3";
import RingChart2 from "@/components/ChartsTime/RingChart2";
export default {
  props: {
    enterpriseList: {
      type: Array,
      default: () => []
    },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    condition,
    abnormalShutdownDialog,
    dialogDig,
    LineChart3,
    RingChart2
  },
  data() {
    return {
      isShowDetail: false,
      data: [],
      listState: [],
      dialogVisible: false,
      detail: {},
      series: [],
      param: {},
      formListBig: {
        deviceList: []
      },
      dialogTableVisibleBig: false,
      titleBig: '',
      historyQuery:{},
      tableData: {
        source: [
          ['product', 1,2,3,4],
          ['9998',2,4,6,2]
        ],
        // 系列
        series: [
          { type: 'bar',maxBarWidth: '15' },
          { type: 'bar',maxBarWidth: '15' },
          { type: 'bar',maxBarWidth: '15' },
          { type: 'bar',maxBarWidth: '15' },
        ],
      },
      timeList:  [1,1,1],
    };
  },
  computed: {
    // conditionFrom() {
    //   console.log(5654554)
    //   console.log(JSON.parse(JSON.stringify(this.$store.state.condition)))
    //   return JSON.parse(JSON.stringify(this.$store.state.condition));
    // },
  },
  watch: {
    // '$store.state.condition.startTime': {
    //   handler(newStartTime) {
    //     this.condition.startTime = newStartTime;
    //   },
    //   immediate: true // 立即执行一次
    // }
  },
  created() {
    // if (this.$store.state.condition.startTime) {
    //   this.conditionFrom = JSON.parse(JSON.stringify(this.$store.state.condition))
    // }
  },
  beforeDestroy() {
    this.data = []
  },
  methods: {
    handleDetails() {
      this.$router.push({ path: 'abnormalDetails', query: { ...this.param, activeName:this.activeName } });
    },
    dialogClose() {
      this.dialogVisible = false
    },
    handleDetail(item) {
      this.dialogVisible = true
      this.detail = item
    },
    search(condition) {
      const conditionFrom = JSON.parse(JSON.stringify(condition))
      if (!this.$store.state.condition.enterprise) {
        // conditionFrom.enterprise = conditionFrom.enterprise[0]
        this.$store.commit('setFormDate',conditionFrom)
      }

      // this.$store.commit('setFormDate',condition)
      this.data = []
      this.param = {
        ...condition.query,
        teamIds: condition.enterprise,
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
      }
      this.isShowDetail = this.param.type === 1
      this.param.type = 1
      // console.log('0000')
      // debugger
      API.getAbnormalShutdown(this.param).then(res => {
        if (res.message.data) {
          this.isQuery = false
          this.data = res.message.data
          debugger
          this.$nextTick(() => {
            for (let i = 0; i < this.data.length; i++) {
              if(this.data[i].deviceList.length && this.data[i].deviceList[0].abnormalList.length){
                  this.myEcharts2(`selected${i}enterprise${i}`, i);
              }
              if(this.data[i].deviceList.length && this.data[i].deviceList[0].abnormalList.length){
                this.myEcharts3(`selected${i}time${i}`, i);
              }
              const table2 = []
              for (let j = 0; j < this.data[i].deviceList.length; j++) {
                table2.push({
                  ...this.data[i].deviceList[j].abnormalList[0],
                  name: this.data[i].deviceList[j].deviceName
                })
                if (this.data[i].deviceList[j].valueList) {
                  let dataOld = this.data[i].deviceList[j].valueList
                  let dataNew = [];    //新数组
                  dataOld.map(item => {
                    let obj = {
                      value: item.count,
                      name: item.reason,
                    }
                    dataNew.push(obj);
                  });
                  this.series = dataNew
                  let device = this.data[i].deviceList[j]
                  if (device.valueList.length) {
                    let deviceId = device.deviceId
                    this.myEcharts(deviceId ? deviceId : `device${i}${j}`);
                  }
                } else {
                  // this.data[i].deviceList[j].valueList = []
                }


              }
              this.data[i].table2 = table2
              // console.log(table2)
              console.log(this.data)
              this.$forceUpdate();
            }

          })
        }
        // console.log(this.data)
      })
    },
    myEcharts(id) {
      this.$echarts.init(document.getElementById(id)).dispose()
      let myChart = this.$echarts.init(document.getElementById(id));
      let option = {
        // title: {
        //     text: 'Referer of a Website',
        //     subtext: 'Fake Data',
        //     left: 'center'
        // },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {d}%'
        },
        legend: {
          bottom: "0%",
          type: 'scroll',
        },
        color: ['#FB5052', '#FFCC18', '#B076FF', '#4CBBF9', '#006df0', '#0acf97', '#fc7f03', '#d149fe', '#fe6d65', '#fcd02d', '#9ad864'],
        series: [
          {
            // name: 'Access From',
            type: 'pie',
            radius: '50%',
            data: this.series,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            itemStyle: {
              normal: {
                label: {
                  show: true,
                  formatter: '{b} : {d}%'
                },
                labelLine: {show: true}
              }
            }
          }
        ]
      };
      myChart.setOption(option);
      // 图表的自适应
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    myEcharts2(id, index) {
      this.$echarts.init(document.getElementById(id)).dispose()
      let myChart = this.$echarts.init(document.getElementById(id));
      let xData = []
      let dateData = []
      let series = []
      let legend = []
      let len = this.data[index].deviceList.length
      if (len) {
        for (let i=0; i< len; i++){
          let item = this.data[index].deviceList[i]
          legend.push(item.deviceName)
          if(i===0){
            item.abnormalList.map(record => {
              if (record.time !== this.$t('all')) {
                xData.push(record.time);
                dateData.push(record.time)
              }
            });
          }
          let newData = []
          for (let k=0;k<dateData.length;k++) {
            let flag=false
            for (let j=0;j<item.abnormalList.length;j++) {
              let subItem = item.abnormalList[j]
              if(dateData[k]==subItem.time){
                newData[k] = {date: subItem.time, time: subItem.time, value: subItem.count, data: null};
                flag= true
                break
              }
            }
            if(!flag){
              newData[k] = {date: dateData[k], value: null, data: null};
            }
          }
          series[i]={
            type:"bar",
            // barMinWidth: '5px',
            name: item.deviceName,
            data: newData
          }
        }
        // console.log(series)
      }
      let option = {
        // 标题
        title: {
          text: this.$t('cishu'),
          top:'-8px',
          textStyle: {
            fontSize: '12px',
            fontWeight: 'normal',
            // paddingTop: '15px',
            paddingBottom: '20px',
            lineHeight: '30',
            color: '#666'
          }
        },
        tooltip: {
          trigger: "axis",
          confine: true, // 限制在图表区域内
          // triggerOn: 'none',
          formatter: function (params) {
            let relVal = params.length ? params[0].data.date : "";
            for (let i = 0, l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}<span style="display:inline-block;width:500px;">${params[i].seriesName}</span> <span>${params[i].value || '-'}</span> `;
            }
            return relVal;
          }
        },
        // 图例
        legend: {
          data: legend,
          bottom: "50",
          type: 'scroll'
        },
        // 图表区域大小
        grid: {
          top:30,
          left: 0,
          right: 10,
          bottom: 80,
          containLabel: true, // 包含标签
        },
        // X轴
        xAxis: {
          type: "category",
          // interval: 100,
          data: xData
        },
        // y轴
        yAxis: {
          type: "value",
          // splitLine: {
          //   lineStyle: {
          //     type: "dashed",
          //     color: '#aaa'
          //   }
          // },
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ],
        series
      };
      //console.log(option.dataset.dimensions)
      this.$nextTick(()=>{
        myChart.setOption(option);
      })

      // 图表的自适应
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    myEcharts3(id, index) {
      this.$echarts.init(document.getElementById(id)).dispose()
      let myChart = this.$echarts.init(document.getElementById(id));
      let xData = []
      let dateData = []
      let series = []
      let legend = []
      let len = this.data[index].deviceList.length
      if (len) {
        for (let i=0; i< len; i++){
          let item = this.data[index].deviceList[i]
          legend.push(item.deviceName)
          if(i===0){
            item.abnormalList.map(record => {
              if (record.time !== this.$t('all')) {
                xData.push(record.time);
                dateData.push(record.time)
              }
            });
          }
          let newData = []
          for (let k=0;k<dateData.length;k++) {
            let flag=false
            for (let j=0;j<item.abnormalList.length;j++) {
              let subItem = item.abnormalList[j]
              if(dateData[k]==subItem.time){
                newData[k] = {date: subItem.time, time: subItem.time, value: subItem.countTime, data: null};
                flag= true
                break
              }
            }
            if(!flag){
              newData[k] = {date: dateData[k], value: null, data: null};
            }
          }
          series[i]={
            type:"bar",
            // barMinWidth: '5px',
            name: item.deviceName,
            data: newData
          }
        }
        // console.log(series)
      }
      let option = {
        // 标题
        title: {
          text: this.$t('timeHour'),
          top:'-8px',
          textStyle: {
            fontSize: '12px',
            fontWeight: 'normal',
            // paddingTop: '15px',
            paddingBottom: '20px',
            lineHeight: '30',
            color: '#666'
          }
        },
        tooltip: {
          trigger: "axis",
          confine: true, // 限制在图表区域内
          // triggerOn: 'none',
          formatter: function (params) {
            let relVal = params.length ? params[0].data.date : "";
            for (let i = 0, l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}<span style="display:inline-block;width:500px;">${params[i].seriesName}</span> <span>${params[i].value || '-'}</span> `;
            }
            return relVal;
          }
        },
        // 图例
        legend: {
          data: legend,
          bottom: "50",
          type: 'scroll'
        },
        // 图表区域大小
        grid: {
          top:30,
          left: 0,
          right: 10,
          bottom: 80,
          containLabel: true, // 包含标签
        },
        // X轴
        xAxis: {
          type: "category",
          // interval: 100,
          data: xData
        },
        // y轴
        yAxis: {
          type: "value",
          // splitLine: {
          //   lineStyle: {
          //     type: "dashed",
          //     color: '#aaa'
          //   }
          // },
        },
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100
          },
          {
            start: 0,
            end: 100
          }
        ],
        series
      };
      //console.log(option.dataset.dimensions)
      this.$nextTick(()=>{
        myChart.setOption(option);
      })

      // 图表的自适应
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    // 查看大图
    handleCheckBig(item) {
      let that = this
      that.formListBig = JSON.parse(JSON.stringify(item))
      if (that.formListBig.valueList) {
        that.formListBig.deviceId = that.formListBig + "1"
        that.formListBig.deviceList = []
        that.formListBig.valueList.forEach(record => {
          const tempItem = {
            value: record.count.toFixed(),
            name: record.reason
          }
          that.formListBig.deviceList.push(tempItem)
        })
        console.log(that.formListBig)
      }
      console.log(that.formListBig)
      // this.$forceUpdate()
      this.titleBig = that.formListBig.deviceName
      this.dialogTableVisibleBig = true
    },
    handleClose() {
      this.formListBig = {}
      this.formListBig.deviceList = []
    },
  },
};
</script>

<style lang="scss" scoped>
.printing-count {
  .right-center {
    margin: auto 0 auto 10px;
  }

  .control-box-width {
    width: 100%;

    .left {
      width: 94%;

      ::v-deep .el-select__tags-text {
        display: inline-block;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      ::v-deep .el-select .el-tag__close.el-icon-close {
        top: -7px;
      }

      .item-component {
        width: 21%;
      }

      .item-component-select {
        width: 30%;
      }
    }

  }

  .ml10 {
    margin-left: 2%;
  }

  .content {
    height: calc(100vh - 260px);
    overflow: auto;

    .chartLayout {
      display: flex;
      flex-wrap: wrap;
    }

    .chart {
      width: calc(32% - 10px);
      height: 400px;
      border: 1px solid #e4e7ed;
      padding: 10px 20px;
      margin: 10px;

      .title {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .deviceName {
          width: 73%;
          line-height: 36px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .echatsImg {
        width: 90%;
        height: 300px;
      }
    }
  }
}
.echatsImg{
  width: 100%;
  height: 300px;
}
h2 {
  margin: 10px 0 18px;
}
::v-deep .table-box table thead th .cell{
  color: #606266;
}
</style>
