<template>
  <!--  日清周清：印量分析-->
  <div>
    <div class="section">
      <condition :enterpriseId="enterpriseId" @changeId="changeIdValue" :downLoading="true" :dateTime="true" :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :tableNumber="tableNumber" :teamGroup="teamGroup" :dateStart="false" :equipment="true" :conditionFrom="conditionFrom" @search="search" @loading="loading"></condition>
    </div>
    <div class="content"  style="padding: 0 0 0 20px;height: 64vh;overflow-y: auto;">
      <div class="table-box">
        <el-table
            ref="tableData"
            show-summary
            :data="tableData"
            border
            style="width: 100%;"
        >
          <el-table-column
              prop="number"
              :label="$t('no')"
              align="left"
              width="50"
          ></el-table-column>
          <el-table-column
              prop="name"
              :label="$t('deviceName')"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="startDate"
              :label="$t('startTime')"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="endDate"
              :label="$t('endTime')"
              align="center"
          >
          </el-table-column>
          <el-table-column
              prop="allValue"
              :label="$t('zongyinzhangshu')"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="okValue"
              :label="$t('youxiaoyinzhang')"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="effecPrintTime"
              :label="$t('effective_duration_minutes')"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="noValue"
              :label="$t('guobanyinzhang')"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="testPrintTime"
              :label="$t('overrun_duration_minutes')"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="wuValue"
              :label="$t('wuxiaoyinzhang')"
              align="center"
          ></el-table-column>
          <el-table-column
              prop="invalidPrintTime"
              :label="$t('ineffective_duration_minutes')"
              align="center"
          ></el-table-column>
        </el-table>

      </div>
    </div>
  </div>
</template>

<script>
import condition from './condition'
import API from '@/api';
export default {
  name: "watermarkingCapacity",
  props: {
    enterpriseIdValue: '',
    enterpriseList: {
      type: Array,
      default: () => []
    },
    // teamGroup:{
    //   type: Array,
    //   default: () => []
    // },
    // tableNumber:{
    //   type: Array,
    //   default: () => []
    // },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  components: { condition },
  data() {
    return {
      historyQuery:{},
      filter: {
        date: undefined,
        startTime: undefined,
        endTime: undefined,
        orderId: '',
        teamId: '',
        teamGroupId: '',
        equipmentDate: '',
        groupId: '',
        deviceId: ''
      },
      tableImport: {
        count: 1000000,
        page: 1,
        teamId: '',
        name: '',
        icollection: 1,
        type: -1,
      },
      tableNumber: [], // 数采设备
      teamGroup: [], // 班组
      enterpriseId: '',
      tableData: [],
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
                time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
                time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      // 柱形颜色
      // barColor: [
      //   // eslint-disable-next-line new-cap,no-new-require
      //   new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
      //     { offset: 0, color: '#FA460D' },
      //     { offset: 1, color: '#F7B515' },
      //   ]),
      // ],
    }
  },
  mounted() {

  },
  watch:{
    conditionFrom: {
      deep: true,
      handler(val) {
        // console.log(val)
        if (typeof val.enterprise === 'object') {
          if (this.tableNumber.length < 1) {
            // this.tableImport.teamId
            API.CompanyPreserveList({...this.tableImport, teamId: val.enterprise[0]})
                .then((res) => {
                  this.tableNumber = res.message.data.list;
                  // this.tableImport.total = res.totalCount;
                })
                .catch((mgs) => {
                  console.log(mgs);
                });
          }
        } else {
          if (this.tableNumber.length < 1) {
            API.CompanyPreserveList({...this.tableImport, teamId: val.enterprise})
                .then((res) => {
                  this.tableNumber = res.message.data.list;
                  // this.tableImport.total = res.totalCount;
                })
                .catch((mgs) => {
                  console.log(mgs);
                });
          }
        }
      }
    }
  },
  created() {
    // this.getList()
    if (this.tableNumber.length < 1) {
      // this.tableImport.teamId
      API.CompanyPreserveList({...this.tableImport, teamId: localStorage.getItem('teamIds')})
          .then((res) => {
            this.tableNumber = res.message.data.list;
            // this.tableImport.total = res.totalCount;
          })
          .catch((mgs) => {
            console.log(mgs);
          });
    }
  },
  methods: {
    getList() {
      API.getDateDetail({...this.filter}).then(res => {
        this.tableData = res.message.data
      })
    },
    changeIdValue(value) {
      if (value !== '111') {
        if (value) {
          API.getClassDateList({deviceId: value}).then((response) => {
            console.log(response)
            this.teamGroup = response.message.data;
          });
        } else {
          this.teamGroup = []
        }
      }
    },
    search(condition) {
      // console.log(condition)
      if (!this.$store.state.condition.enterprise) {
        // console.log(this.$store.state.condition)
        this.$store.commit('setFormDate',condition)
      }
      this.echartList = []
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        teamId:condition.enterprise
        // deviceId: condition.query.deviceId,
        // groupId: condition.query.groupId
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
        // deviceId: condition.query.deviceId,
        // groupId: condition.query.groupId
      }
      this.getList()
      this.showDisplay = true
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    loading(condition) {
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        teamId:condition.enterprise,
        count: 10,
        page: 1,
      }
      this.showDisplay = true
      require.ensure([], () => {
        const {export_json_to_excel} = require('@/views/ExcelLeadOut/Export2Excel'); // 注意这个Export2Excel路径
        const tHeader = [
          this.$t('no'),
          this.$t('device_add_name'),
          this.$t('startTime'),
          this.$t('endTime'),
          this.$t('zongyinzhangshu'),
          this.$t('youxiaoyinzhang'),
          this.$t('effective_duration_minutes'),
          this.$t('guobanyinzhang'),
            this.$t('overrun_duration_minutes'),
            this.$t('wuxiaoyinzhang'),
            this.$t('ineffective_duration_minutes')
        ]; // 表格标题
        const filterVal = [
          'number',
          'name',
          'startDate',
          'endDate',
          'allValue',
          'okValue',
          'effecPrintTime',
          'noValue',
            'testPrintTime',
            'wuValue',
            'invalidPrintTime'
        ]; // 标题属性key值
        API.getDateDetail({...this.filter}).then(res => {
          const list = res.message.data; // 把要导出的数据tableData存到list

          // 计算合计
          const totals = {
            number: this.$t('heji'),
            name: '',
            startDate: '',
            endDate: '',
            allValue: 0,
            okValue: 0,
            effecPrintTime: 0,
            noValue: 0,
            testPrintTime: 0,
            wuValue: 0,
            invalidPrintTime: 0,
          };

          list.forEach(item => {
            totals.allValue += Number(item.allValue) || 0;
            totals.okValue += Number(item.okValue) || 0;
            totals.effecPrintTime += Number(item.effecPrintTime) || 0;
            totals.noValue += Number(item.noValue) || 0;
            totals.testPrintTime += Number(item.testPrintTime) || 0;
            totals.wuValue += Number(item.wuValue) || 0;
            totals.invalidPrintTime += Number(item.invalidPrintTime) || 0;
          });

          // 将合计行添加到数据中
          list.push(totals);

          const data = this.formatJson(filterVal, list);
          export_json_to_excel(tHeader, data, this.$t('printing_quantity_analysis_list')); // 最后一个是表名字
        })
      });
    },

  }
}
</script>

<style scoped lang="scss">
::v-deep .table-box table thead th .cell{
  color: #606266;
  white-space: normal;
}
.but-export {
  position: absolute;
  right: 100px;
  top: 22px;
}
</style>
