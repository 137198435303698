<template>
  <!--  日清周清：保养&点检-->
  <div>
    <div class="section">
      <condition :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :downLoading="true" :conditionFrom="conditionFrom" @search="search" @loading="loading"></condition>
    </div>

    <div class="content" style="padding: 0 0 0 20px">
      <div class="table-box" >
        <el-table
          :data="maintainData"
          border
          style="width: 100%;"
        >
          <el-table-column
            prop="name"
            :label="$t('deviceName')"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="project"
            :label="$t('replacement_item')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="date"
            :label="$t('submit_time')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="state"
            :label="$t('device_state')"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.state === 0 ? $t('unreviewed') : scope.row.state === 1 ? '通过' : scope.row.state === 2 ? '驳回' : $t('overtime_cancellation') }}
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            :label="$t('remark')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="person"
            :label="$t('reporter')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="approvePerson"
            :label="$t('approver')"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="approveDate"
            :label="$t('approval_time')"
            align="center"
          ></el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
            v-show="totalCount>0"
            :current-page="filter.page"
            :page-sizes="[10, 20, 30, 40]"
            :total="totalCount"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
<!--    分页-->

  </div>
</template>

<script>
import API from '@/api';
import condition from './condition'
export default {
  name: "maintainPoint.vue",
  components: { condition },
  props: {
    enterpriseList: {
      type: Array,
      default: () => []
    },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showDisplay: false,
      filter: {
        startTime: undefined,
        endTime: undefined,
        count: 10,
        page: 1,
        totalCount: 0
      },
      totalCount: 0,
      dialog:false,
      options: {
        status: [],
      },
      maintainData: [],
      scoreData: [],
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
              time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
              time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      activeName: 'first',
      tabPosition: 'top',
      historyQuery:{},
      downLoading: false
    };
  },
  mounted() {

  },
  created() {
    // console.log(this.filter)
  },
  methods: {
    startTimeChange(val){
      if(this.filter.endTime && new Date(val).getTime() > new Date(this.filter.endTime).getTime()){
        this.filter.startTime = null
      }else{
        this.filter.startTime = val
      }
    },
    endTimeChange(val){
      if(this.filter.startTime && new Date(val).getTime() < new Date(this.filter.startTime).getTime()){
        this.filter.endTime = null
      }else{
        this.filter.endTime = val
      }
    },
    getDate() {
      this.filter.startTime = this.filter.startTime
      this.filter.endTime = this.filter.endTime
      API.getRegularList({...this.filter}).then(res => {
        console.log(res)
        this.maintainData = res.message.data.list
        this.totalCount = res.message.data.totalCount
        // console.log(this.filter)
      })
    },
    // 查询
    search(condition) {
      if (!this.$store.state.condition.enterprise) {
        console.log(this.$store.state.condition)
        this.$store.commit('setFormDate',condition)
      }
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        count: 10,
        page: 1,
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise,
        count: this.filter.count,
        page: this.filter.page,
      }
      this.filter.count = 10
      this.filter.page = 1
      this.getDate()
      this.showDisplay = true
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    loading(condition) {
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        teamId:condition.enterprise,
        count: 10,
        page: 1,
      }
      this.showDisplay = true
      require.ensure([], () => {
        const {export_json_to_excel} = require('@/views/ExcelLeadOut/Export2Excel'); // 注意这个Export2Excel路径
        const tHeader = [
          this.$t('device_add_name'),
          this.$t('replacement_item'),
          this.$t('submit_time'),
          this.$t('device_state'),
          this.$t('remark'),
          this.$t('reporter'),
          this.$t('approver'),
          this.$t('approval_time'),
        ]; // 表格标题
        const filterVal = [
          'name',
          'project',
          'date',
          'state',
          'remark',
          'person',
          'approvePerson',
          'approveDate',
        ]; // 标题属性key值
        API.getRegularList({count:10000000,page: 1,startTime:condition.query.startTime,endTime:condition.query.endTime}).then(res => {
          res.message.data.list.forEach(item =>{
            item.state = item.state === 0 ? this.$t('unreviewed') : item.state === 1 ? '通过' : item.state === 2 ? '驳回' : this.$t('overtime_cancellation')
          })
          const list = res.message.data.list; // 把要导出的数据tableData存到list
          const data = this.formatJson(filterVal, list);
          export_json_to_excel(tHeader, data, this.$t('periodic_replacement')); // 最后一个是表名字
        })
      });
    },
    // 每页显示个数改变时会触发
    handleSizeChange(val) {
      this.filter.count = val;
      this.filter.page = 1;
      this.getDate();
    },
    // 当前页数改变时会触发
    handleCurrentChange(val) {
      this.filter.page = val;
      this.getDate();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--card > .el-tabs__header{
  border-bottom: 0px solid #e4e7ed;
}
.content{
  height: calc(100vh - 290px);
  overflow: auto;
  .chart{
    width: 94%;
    height: 500px;
    .title{
      display: flex;
      justify-content: space-between;
    }

    .echatsImg{
      width: 100%;
      height: 300px;
    }
  }
}
.content ::v-deep .el-tabs__nav{
  height: 50px !important;
  display: inline-flex;
  align-items: center;
}
.content ::v-deep .el-tabs__item{
  //height: 11.11%;
  height: 50px !important;
  width: 100%;
  display: flex;
  align-items: center;
}
.content ::v-deep .el-tabs__item:last-child{
  border-right: 1px solid #e4e7ed;
}
//height: calc(100vh - 50px);
.content .section{
  height: 100%;
}
::v-deep .table-box table thead th .cell{
  color: #606266;
}
.pagination-box {
  padding-top: 20px;
}
//#606266
</style>
